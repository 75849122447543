@tailwind base;
@tailwind components;
@tailwind utilities;

.tox-tinymce {
  @apply flex flex-col flex-grow border-0 rounded-none !important;
}

.tox-editor-header {
  @apply shadow-none border-b-2 border-gray-200 !important;
}

.tox-statusbar {
  display: none !important;
}

#editor-view-mode-signature {
  @apply leading-[22.4px] mx-auto md:w-[80%] lg:w-[50%] !important;
}

#editor-view-mode-signature h1,
#editor-view-mode-signature h2,
#editor-view-mode-signature h3,
#editor-view-mode-signature h4,
#editor-view-mode-signature h5,
#editor-view-mode-signature h6,
#editor-view-mode-signature p,
#editor-view-mode-signature table {
  @apply block mx-3 my-3 !important;
}

#editor-view-mode-signature a {
  @apply underline text-blue-600 !important;
}

#editor-view-mode-signature ol,
#editor-view-mode-signature ul {
  @apply block mx-12 my-3 !important;
}

#editor-view-mode-signature h1 {
  @apply text-2xl font-bold !important;
}

#editor-view-mode-signature h2 {
  @apply text-xl font-bold !important;
}

#editor-view-mode-signature h3 {
  @apply text-lg font-semibold !important;
}

#editor-view-mode-signature h4 {
  @apply text-base font-normal !important;
}

#editor-view-mode-signature h5 {
  @apply text-sm font-normal !important;
}

#editor-view-mode-signature h6 {
  @apply text-xs font-normal !important;
}

#editor-view-mode-signature p {
  @apply block mx-4 !important;
}

#editor-view-mode-signature ol li {
  @apply list-decimal px-3 !important;
}

#editor-view-mode-signature ul li {
  @apply list-disc px-3 !important;
}

#editor-view-mode-signature table {
  @apply min-w-full divide-y divide-gray-300 !important;
}

#editor-view-mode-signature table th {
  @apply py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 !important;
}

#editor-view-mode-signature table th {
  @apply py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 !important;
}

#editor-view-mode-signature table tbody {
  @apply w-full divide-y divide-gray-200 !important;
}

#editor-view-mode-signature table tr {
  @apply !important;
}

#editor-view-mode-signature table td {
  @apply whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0 !important;
}

#editor-view-mode-signature .field {
  @apply border border-rose-300 bg-rose-100 uppercase hover:bg-rose-200 text-rose-700 shadow-sm focus-visible:outline-rose-200 rounded-sm px-2 py-1 text-xs font-semibold !important;
}

#decline-button {
  @apply rounded-md bg-rose-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-rose-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-700
}

#accept-button {
  @apply rounded-md bg-green-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700
}